import {
  ContentUsageDrawerContext,
  ContentUsageDrawerParent,
} from "@/content-usage/drawer/ContentUsageDrawerContext"
import { InlineContentDrawerFormStore } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import GenerateContentWithAIProvider, {
  useGenerateContentWithAI,
} from "@/content/ai/GenerateContentWithAIProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const ContentUsageSubmissionsDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "content-usage-submissions-drawer-content" */ "@/content-usage/drawer/ContentUsageSubmissionsDrawerContent"
    )
)
const ContentUsageScormCompletionsDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "content-usage-scorm-completions-drawer-content" */ "@/content-usage/drawer/sidebar/scorm-completions/ContentUsageScormCompletionsDrawerContent"
    )
)
const ContentUsageDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "content-usage-drawer-content" */ "@/content-usage/drawer/ContentUsageDrawerContent"
    )
)

function ContentUsageDrawer() {
  const drawer = useContentUsageDrawer()

  return (
    <GenerateContentWithAIProvider
      prompt={
        drawer.params.drawerAIPrompt
          ? decodeURIComponent(drawer.params.drawerAIPrompt)
          : undefined
      }
      referenceUrl={
        drawer.params.drawerAIReferenceUrl
          ? decodeURIComponent(drawer.params.drawerAIReferenceUrl)
          : undefined
      }
      referenceEmbeddingSourceIds={
        drawer.params.drawerAIReferenceEmbeddingSourceIds
          ? JSON.parse(
              decodeURIComponent(drawer.params.drawerAIReferenceEmbeddingSourceIds)
            )
          : undefined
      }
      referenceModuleContentUsageId={drawer.params.drawerAIReferenceModuleContentUsageId}
    >
      <Modal />
    </GenerateContentWithAIProvider>
  )
}

function Modal() {
  const [contentUsageForm, setContentUsageForm] = useState<InlineContentDrawerFormStore>()
  const [drawerParentEntity, setDrawerParentEntity] = useState<
    ContentUsageDrawerParent | undefined
  >()

  const drawer = useContentUsageDrawer()
  const classes = useStyles()
  const location = useLocation()
  const modal = useInitUnsavedChangesModalContext()
  const { stopGenerating } = useGenerateContentWithAI()

  useEffect(() => {
    // If the drawer is closed, clear the form and sidebar entity
    if (!drawer.isOpen) {
      setContentUsageForm(undefined)
      setDrawerParentEntity(undefined)
    }
  }, [drawer.isOpen])

  return (
    // If leaving with unsaved changes, display the unsaved changes modal
    <UnsavedChangesModalProvider {...modal}>
      <ContentUsageDrawerContext.Provider
        value={{
          contentUsageForm,
          setContentUsageForm,
          drawerParentEntity,
          setDrawerParentEntity,
        }}
      >
        <DiscoDrawer
          size={"xl"}
          open={drawer.isOpen}
          onClose={handleCloseDrawer}
          drawerAnchorKey={location.search}
          containerClasses={{ drawerContainer: classes.drawerContainer }}
          fullScreen
        >
          {renderContent()}
        </DiscoDrawer>
      </ContentUsageDrawerContext.Provider>
    </UnsavedChangesModalProvider>
  )

  function renderContent() {
    if (drawer.params.drawerSubmissionsTab)
      return (
        <ContentUsageSubmissionsDrawerContent
          contentUsageId={drawer.params.drawerContentUsageId}
        />
      )
    if (drawer.params.drawerScormCompletionsTab)
      return (
        <ContentUsageScormCompletionsDrawerContent
          contentUsageId={drawer.params.drawerContentUsageId}
        />
      )

    return <ContentUsageDrawerContent />
  }

  function handleCloseDrawer() {
    // Check that there are no unsaved changes before closing
    modal.handleLeave({
      onLeave: () => {
        stopGenerating()
        drawer.close()
      },
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  drawerContainer: {
    padding: 0,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
    },
  },
}))

export default observer(ContentUsageDrawer)
