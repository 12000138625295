/**
 * @generated SignedSource<<ad7619b73ae1e10cf0c0a4d6c03b4d56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizSubmissionsTable_ContentUsageFragment$data = {
  readonly passPercentage: number | null;
  readonly " $fragmentType": "QuizSubmissionsTable_ContentUsageFragment";
};
export type QuizSubmissionsTable_ContentUsageFragment$key = {
  readonly " $data"?: QuizSubmissionsTable_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionsTable_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizSubmissionsTable_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passPercentage",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "1d413ff864b525c3ca8d2c81ddb0907c";

export default node;
