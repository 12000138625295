import makeUseStyles from "@assets/style/util/makeUseStyles"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  media: MediaResult
  onRemove: (media: MediaResult) => void
}

function AIFormUploadedReferencesListItem(props: Props) {
  const { media, onRemove, testid } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.thumbnailContainer}>
          <DiscoIcon icon={"file-dock"} />
        </div>
        <div className={classes.titleContainer}>
          <div className={classes.customTitleContainer}>
            <DiscoText
              testid={`${testid}.title`}
              variant={"body-md-600"}
              truncateText={2}
            >
              {media.name}
            </DiscoText>
          </div>
        </div>
      </div>
      <div>
        <DiscoIconButton onClick={() => onRemove(media)}>
          <DiscoIcon icon={"close"} />
        </DiscoIconButton>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.medium,
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(2),
    gap: theme.spacing(0.5),

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  customTitleContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    gap: theme.spacing(0.5),

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  thumbnailContainer: {
    position: "relative",
    minWidth: "91px",
    width: "91px",
    height: "46px",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.medium,
    marginRight: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& svg path": {
      color: theme.palette.text.primary,
    },
  },
}))

export default AIFormUploadedReferencesListItem
