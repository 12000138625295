/**
 * @generated SignedSource<<d8b69b9d35c3072a0d157b5ba11db400>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizSubmissionFormContentUsageFragment$data = {
  readonly confirmationModalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"CurriculumConfirmationModalConfirmationModalFragment">;
  } | null;
  readonly " $fragmentType": "QuizSubmissionFormContentUsageFragment";
};
export type QuizSubmissionFormContentUsageFragment$key = {
  readonly " $data"?: QuizSubmissionFormContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionFormContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizSubmissionFormContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "confirmationModalContent",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CurriculumConfirmationModalConfirmationModalFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "c8add5c8c773dd45089d32ef4ffb5bec";

export default node;
