import { QuizQuestionProps } from "@/content-usage/drawer/quizzes/editor/QuizEditorDrawerContent"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { WebFormQuestionType } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import { GenerationStatus } from "@/content/ai/GenerateContentWithAIProvider"
import GenerateContentWithAIStatusPopup from "@/content/ai/GenerateContentWithAIStatusPopup"
import ContentUtils from "@/content/util/contentUtils"
import WebFormEditor from "@/web-form/editor/WebFormEditor"
import { WEB_FORM_QUESTIONS_MAX_WIDTH } from "@/web-form/utils/webFormConstants"
import { WebFormEditorProvider } from "@/web-form/utils/webFormEditorUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSpinner } from "@disco-ui"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"

interface QuizEditorQuestionsFormProps extends TestIDProps, QuizQuestionProps {
  readOnly?: boolean
}

function QuizEditorQuestionsForm({
  testid = "QuizEditorQuestionsForm",
  form,
  readOnly,
}: QuizEditorQuestionsFormProps) {
  const classes = useStyles()
  const template = ContentUtils.webFormTemplateForContent(form.state.contentType)
  const questions = form?.state.content.webFormRevision?.questions
  const { isOpen, onClose, onOpen } = useDisclosure(false)
  const [generationStatus, setGeneratationStatus] = useState<GenerationStatus>(null)
  const drawer = useContentUsageDrawer()
  const useAI = drawer.params.drawerQuizUseAI === "1"
  if (!questions) return null

  return (
    <div data-testid={testid} className={classes.container}>
      <WebFormEditorProvider
        template={template}
        form={form}
        questionsConfig={getQuestionsConfig()}
      >
        <WebFormEditor
          emptyState={
            useAI
              ? getAiEmptyState()
              : {
                  icon: <></>,
                  title: ``,
                  subtitle: ``,
                  ctaText: `Add Question`,
                }
          }
          classes={{ dragDropContainer: classes.dragDropContainer }}
          allowedQuestionTypes={getAllowedQuestionTypes()}
          readOnly={readOnly}
          editorConfig={"web_form_question"}
          // Don't allow generate options if we are using AI to generate the quiz
          showAIGenerateButton={!form?.state.content.isAiGenerated}
          onQuizGenerationStatusChange={setGeneratationStatus}
          onQuizGenerationStart={onOpen}
        />
        {isOpen && (
          <GenerateContentWithAIStatusPopup
            statuses={[{ name: "Quiz", status: generationStatus }]}
            onClose={onClose}
          />
        )}
      </WebFormEditorProvider>
    </div>
  )

  function getQuestionsConfig() {
    return questions?.map(() => {
      return {
        disabled: readOnly,
        classes: undefined,
        expanded: true,
      }
    })
  }

  function getAllowedQuestionTypes() {
    const types: WebFormQuestionType[] = ["single_select", "multiple_select"]
    if (template === "survey") types.push("rating", "ranking", "written_answer")
    return types
  }

  function getAiEmptyState() {
    if (generationStatus === "loading") {
      return {
        component: () => (
          <div className={classes.aiEmptyStateContainer}>
            <DiscoSpinner />
          </div>
        ),
      }
    }

    return null
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dragDropContainer: {
    width: "100%",
    maxWidth: WEB_FORM_QUESTIONS_MAX_WIDTH,
    padding: theme.spacing(2),
  },
  aiEmptyStateContainer: {
    marginTop: theme.spacing(4),
  },
}))

export default observer(QuizEditorQuestionsForm)
