import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoText } from "@disco-ui"
import classNames from "classnames"

interface Props {
  icon: React.ReactNode
  name: string
  input: React.ReactNode
  hide?: boolean
  errorMessages?: string[]
  classes?: {
    lhs?: string
    rhs?: string
  }
}

function AIFormReferencesListItem({
  icon,
  name,
  input,
  hide,
  errorMessages,
  classes: customClasses,
}: Props) {
  const classes = useStyles()

  if (hide === true) return null
  return (
    <div className={classes.container}>
      <div className={classNames(classes.lhs, customClasses?.lhs)}>
        <div className={classes.iconContainer}>{icon}</div>
        <DiscoText variant={"body-sm-500"}>{name}</DiscoText>
      </div>
      <DiscoFormControl
        errorMessages={errorMessages}
        marginBottom={0}
        className={classes.form}
      >
        <div className={classNames(classes.rhs, customClasses?.rhs)}>{input}</div>
      </DiscoFormControl>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "start",
    minWidth: 0,
    maxWidth: "100%",
  },
  lhs: {
    width: "160px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(0.75),
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
  iconContainer: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.default,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
  },
  rhs: {
    width: "100%",
    minWidth: 0,
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
  form: {
    height: "100%",
  },
}))

export default AIFormReferencesListItem
