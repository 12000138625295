/**
 * @generated SignedSource<<029e3a2a05d884b2e6709bd6ab7e7650>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuizEditorBanner_ContentFragment$data = {
  readonly type: ContentType;
  readonly webForm: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "QuizEditorBanner_ContentFragment";
};
export type QuizEditorBanner_ContentFragment$key = {
  readonly " $data"?: QuizEditorBanner_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizEditorBanner_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizEditorBanner_ContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebForm",
      "kind": "LinkedField",
      "name": "webForm",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "b27178db9f319b59696767a61cd412bd";

export default node;
