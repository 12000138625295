import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { ContentModuleCompletionModalQuery } from "@/content-usage/modules/components/__generated__/ContentModuleCompletionModalQuery.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useConfettiCannon } from "@/core/context/ConfettiContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import Group from "@/core/ui/images/empty-state/disco-ball.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoButton, DiscoModal, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { setSearchParams } from "@utils/url/urlUtils"
import { useEffect } from "react"
import { useLazyLoadQuery } from "react-relay"
import { generatePath } from "react-router"
import { graphql } from "relay-runtime"

interface Props {
  contentUsageId: GlobalID
  onClose(): void
}

/** Fires the confetti cannon whenever the user completes a content module */
function ContentModuleCompletionModal(props: Props) {
  const classes = useStyles()
  const { contentUsageId, onClose } = props

  const { contentUsage } = useLazyLoadQuery<ContentModuleCompletionModalQuery>(
    graphql`
      query ContentModuleCompletionModalQuery($id: ID!) {
        contentUsage: node(id: $id) {
          ... on ContentUsage {
            id
            content {
              name
            }
            curriculum {
              modules {
                edges {
                  node {
                    id
                    releasedAt
                    content {
                      children {
                        edges {
                          node {
                            id
                            content {
                              id
                              type
                              name
                              systemTaskKind
                              ...ContentThumbnailWithDetails_ContentFragment
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: contentUsageId }
  )

  const activeProduct = useActiveProduct()!

  const confettiCannon = useConfettiCannon()
  useEffect(() => {
    if (!contentUsage?.id) return
    confettiCannon()
  }, [confettiCannon, contentUsage?.id])

  if (!contentUsage) return null

  const currentContentModules = Relay.connectionToArray(
    contentUsage.curriculum?.modules
  ).filter((module) => ContentModuleUtils.isReleased(module))

  const currentModuleIndex = currentContentModules.findIndex(
    (module) => module.id === contentUsage.id
  )

  const isLastModule = currentModuleIndex === currentContentModules.length - 1

  const nextModule = isLastModule ? null : currentContentModules[currentModuleIndex + 1]

  const nextContentItemId = nextModule?.content?.children?.edges?.[0]?.node.id

  return (
    <DiscoModal
      isOpen
      onClose={onClose}
      testid={"ContentModuleCompletionModal"}
      modalContentLabel={"Completed module"}
      minHeight={"400px"}
      classes={{
        buttons: classes.buttonsContainer,
        closeButton: classes.closeButton,
      }}
      body={
        <Grid container direction={"column"} alignItems={"center"}>
          <div className={classes.iconContainer}>{<Group />}</div>
          <DiscoText variant={"heading-sm"} align={"center"} marginTop={1}>
            {"Nicely done 🎉 keep up the good work!"}
          </DiscoText>

          <DiscoText
            className={classes.modalSubtitle}
            marginTop={1.5}
            data-testid={"ContentModuleCompletionModal.subtitle"}
            align={"center"}
          >
            {`You completed ${contentUsage.content?.name}!`}
          </DiscoText>
        </Grid>
      }
      buttons={
        <>
          <DiscoButton
            color={nextContentItemId ? "grey" : "primary"}
            variant={nextContentItemId ? "outlined" : "contained"}
            onClick={onClose}
            to={generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
              productSlug: activeProduct.slug,
            })}
            data-testid={"ContentModuleCompletionModal.dashboard-button"}
          >
            {"Back to Dashboard"}
          </DiscoButton>
          {nextModule && nextContentItemId && (
            <DiscoButton
              color={"primary"}
              onClick={onClose}
              to={{
                // Could not use GlobalDrawerParams due to circular import issue
                search: setSearchParams(location.search, {
                  u: Relay.fromGlobalId(nextContentItemId).id,
                  // setting a parameter to undefined removes it from url
                  drawerQuizTab: undefined,
                  drawerQuizRetry: undefined,
                }),
              }}
              data-testid={"ContentModuleCompletionModal.next-section-button"}
            >
              {"Next Module"}
            </DiscoButton>
          )}
        </>
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  iconContainer: {
    margin: theme.spacing(2),
  },
  modalSubtitle: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
  buttonsContainer: {
    width: "100%",
    justifyContent: "center !important",
  },
  closeButton: {
    alignSelf: "start",
  },
}))

export default Relay.withSkeleton({
  component: ContentModuleCompletionModal,
  skeleton: () => null,
})
