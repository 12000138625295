import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import {
  ContentUsageQuizDrawerSubtab,
  useGlobalDrawer,
} from "@/core/context/GlobalDrawerProvider"

export default function useQuizEditorDrawer() {
  const contentUsageDrawer = useContentUsageDrawer()
  const adminContentDrawer = useGlobalDrawer("adminContent")

  // check a required param for the drawer to identify which is open
  const drawer = adminContentDrawer.params.contentId
    ? adminContentDrawer
    : contentUsageDrawer

  return {
    drawer,
    goToRetake() {
      contentUsageDrawer?.setParams({
        submissionId: undefined,
        drawerQuizTab: "questions",
        drawerQuizRetry: "1",
      })
    },
    goToQuestions() {
      contentUsageDrawer?.setParams({ drawerQuizTab: "questions" })
    },
    goToQuestionsEditor() {
      drawer?.setParams(
        drawer.kind === "adminContent"
          ? { view: "questions" }
          : { drawerQuizTab: "questions-editor" }
      )
    },
    goToAiEditor() {
      drawer?.setParams(
        drawer.kind === "adminContent"
          ? { view: "questions" }
          : { drawerQuizTab: "questions-editor", drawerQuizUseAI: "1" }
      )
    },
    /** View Submissions from `contentUsage` drawer - not configured for `adminContent` drawer */
    goToSubmissions(
      tab: Extract<
        ContentUsageQuizDrawerSubtab,
        "submissions-overview" | "submissions-detail" | "submissions-summaries"
      >,
      submissionId?: string
    ) {
      contentUsageDrawer?.setParams({
        drawerQuizTab: tab,
        // make sure we never open submissions detail with a single id already set, unless specifically set
        submissionId,
      })
    },
    navigateAway() {
      drawer?.setParams({
        [drawer.kind === "adminContent" ? "view" : "drawerQuizTab"]: undefined,
      })
    },
  }
}
