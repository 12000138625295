import { QuizSubmissionEditorFormMutation } from "@/content-usage/drawer/quizzes/submission-editor/__generated__/QuizSubmissionEditorFormMutation.graphql"
import { QuizSubmissionEditorFormState } from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditor"
import QuizSubmissionResultAnswerList from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResultAnswerList"
import QuizSubmissionResultSidebar from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResultSidebar"
import { QuizSubmissionUtils } from "@/content-usage/drawer/quizzes/utils/quizSubmissionUtils"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import {
  QuizRevision,
  UseWebFormSubmissionQueryReturnType,
} from "@/web-form/utils/webFormQueryUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface QuizSubmissionResultProps
  extends TestIDProps,
    UseWebFormSubmissionQueryReturnType {
  form: FormStore<QuizSubmissionEditorFormState, QuizSubmissionEditorFormMutation>
  contentLabel: string
  revision: QuizRevision
  contentUsageId?: GlobalID
}

function QuizSubmissionResult({
  testid = "QuizSubmissionResult",
  form,
  contentLabel,
  result,
  submission,
  revision,
  contentUsageId,
}: QuizSubmissionResultProps) {
  const classes = useStyles()
  const { filteredAnswers, filter, setFilter, currentQuestionId, setCurrentQuestionId } =
    QuizSubmissionUtils.useAnswersListState({ revision, submission })

  return (
    <div data-testid={testid} className={classes.container}>
      <QuizSubmissionResultAnswerList
        revision={revision}
        submission={submission}
        answers={filteredAnswers}
        currentQuestionId={currentQuestionId}
        handleRemoveQuestionId={() => setCurrentQuestionId(null)}
      />
      {submission?.hasAnswerKey && (
        <QuizSubmissionResultSidebar
          form={form}
          result={result}
          submission={submission}
          answers={filteredAnswers}
          contentLabel={contentLabel}
          filter={filter}
          setFilter={setFilter}
          currentQuestionId={currentQuestionId}
          goToQuestion={(id) => setCurrentQuestionId(id)}
          // Pass/Fail quizzes
          contentUsageId={contentUsageId}
          revisionId={revision?.id}
        />
      )}
    </div>
  )
}

const useStyles = makeUseStyles(() => ({
  container: {
    display: "grid",
    overflow: "hidden", // prevent scroll from showing up on drawer
    height: "100%",
    width: "100%",
    gridTemplateColumns: `1fr auto`,
  },
}))

export default observer(QuizSubmissionResult)
