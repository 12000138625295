import { useLabel } from "@/core/context/LabelsContext"
import { NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import WebFormQuestion from "@/web-form/filler/WebFormQuestion"
import { webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment$data } from "@/web-form/utils/__generated__/webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment.graphql"
import { WEB_FORM_QUESTION_TYPE_TITLES } from "@/web-form/utils/useWebFormQuestionTypes"
import {
  indexToLetter,
  ratingOptionDisplayNumber,
} from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoEmptyState,
  DiscoIcon,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import DiscoButtonsGroup from "@disco-ui/button/DiscoButtonsGroup"
import DiscoExpandButton from "@disco-ui/button/DiscoExpandButton"
import DiscoPieChart from "@disco-ui/chart/DiscoPieChart"
import DiscoSimpleHorizontalBarChart from "@disco-ui/chart/DiscoSimpleHorizontalBarChart"
import { Collapse, useTheme } from "@material-ui/core"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import pluralize from "pluralize"
import React from "react"

type Data = {
  uniqueId: string
  label: string
  frequency: number
  percentage: number
  isCorrect: boolean
  letter: string
}

export const QUESTION_REPORT_MAX_WIDTH = 1160

interface QuizSubmissionsQuestionReportsListItemProps extends TestIDProps {
  question: NodeFromConnection<
    webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment$data["questions"]
  >
  isPassFail?: boolean
}

function QuizSubmissionsQuestionReportsListItem({
  testid = "QuizSubmissionsQuestionReportsListItem",
  question,
  isPassFail = false,
}: QuizSubmissionsQuestionReportsListItemProps) {
  const { options, answerKey } = question
  const showChart = Boolean(options?.length && question.type !== "ranking")
  const { isOpen, onToggle } = useDisclosure(showChart)
  const [chartType, setChartType] = React.useState<"pie" | "bar">(
    question.type === "multiple_select" ? "bar" : "pie"
  )
  const memberLabel = useLabel("admin_member")

  const frequencyMap = (options || []).reduce<Record<string, number>>((acc, option) => {
    acc[option.id] = 0
    return acc
  }, {})

  const answers = Relay.connectionToArray(question.answers)
  const skipped = answers.filter((answer) => answer.isSkipped).length

  let totalFrequency = 0
  for (const answer of answers) {
    for (const id of answer.selectedOptions ?? []) {
      frequencyMap[id] += 1
      totalFrequency += 1
    }
  }

  const data = (options || []).map<Data>((option, i) => ({
    uniqueId: option.id,
    label: getOptionLabel(option.label, i),
    frequency: frequencyMap[option.id],
    percentage: frequencyMap[option.id]
      ? parseFloat(
          (
            (frequencyMap[option.id] /
              (chartType === "pie" // show % of times selected
                ? totalFrequency
                : answers.length)) * // show % of respondents selected
            100
          ).toFixed(2)
        )
      : 0,
    isCorrect: answerKey?.correctIds.includes(option.id) ?? false,
    letter: indexToLetter(i),
  }))

  const theme = useTheme()
  const classes = useStyles()
  const detailsTextColor =
    theme.palette.type === "dark" ? "groovy.neutral.500" : "groovy.neutral.400"

  return (
    <DiscoSection
      key={question.id}
      testid={`${testid}.outer-container`}
      className={classes.item}
    >
      <WebFormQuestion
        testid={testid}
        readOnly
        question={question}
        classes={{
          answerHeader: classes.answerHeader,
          questionSection: classes.questionSection,
        }}
        moreActions={
          showChart && (
            <>
              <DiscoButtonsGroup
                variant={"outlined"}
                color={"primary"}
                buttons={[
                  {
                    uniqueKey: "pie",
                    testid: `${testid}.pie-chart-button${
                      chartType === "pie" ? ".selected" : ""
                    }`,
                    onClick: () => setChartType("pie"),
                    content: <DiscoIcon icon={"chart-pie"} color={"currentColor"} />,
                    customClass: classNames({
                      [classes.buttonSelected]: chartType === "pie",
                    }),
                  },
                  {
                    uniqueKey: "bar",
                    testid: `${testid}.bar-chart-button${
                      chartType === "bar" ? ".selected" : ""
                    }`,
                    onClick: () => setChartType("bar"),
                    content: <DiscoIcon icon={"chart-bar"} color={"currentColor"} />,
                    customClass: classNames({
                      [classes.buttonSelected]: chartType === "bar",
                    }),
                  },
                ]}
              />
              <DiscoExpandButton
                testid={`${testid}.collapse-button`}
                onClick={onToggle}
                isOpen={isOpen}
              />
            </>
          )
        }
        answerDetails={
          <>
            <DiscoText
              testid={`${testid}.question-type`}
              variant={"body-sm"}
              color={detailsTextColor}
            >
              {`${WEB_FORM_QUESTION_TYPE_TITLES[question.type]}`}
            </DiscoText>
            <DiscoText variant={"body-sm"} color={detailsTextColor}>
              {" • "}
            </DiscoText>
            <DiscoText
              testid={`${testid}.answer-count`}
              variant={"body-sm"}
              color={detailsTextColor}
            >
              {`Answered: ${answers.length - skipped}`}
            </DiscoText>
            <DiscoText variant={"body-sm"} color={detailsTextColor}>
              {" • "}
            </DiscoText>
            <DiscoText
              testid={`${testid}.skip-count`}
              variant={"body-sm"}
              color={detailsTextColor}
            >
              {`Skipped: ${skipped}`}
            </DiscoText>
          </>
        }
        answerNode={
          <Collapse in={isOpen}>
            <DiscoDivider marginTop={0} marginBottom={0} thickness={2} />
            {renderChart()}
          </Collapse>
        }
      />
    </DiscoSection>
  )
  function renderChart() {
    if (!showChart) return null
    if (!answers.length || answers.every((answer) => answer.isSkipped))
      return (
        <DiscoEmptyState
          testid={testid}
          title={"No answer data to show"}
          subtitle={"No answers have been submitted for this question."}
        />
      )
    if (chartType === "pie")
      return (
        <div className={classes.pieChart}>
          <DiscoPieChart
            testid={`${testid}.pie-chart`}
            data={data}
            legendHeaders={["Options", "Selected by", "Percentage"]}
            formatLegendRow={(rowData) => [
              <React.Fragment key={rowData.uniqueId}>
                <DiscoText
                  testid={`${testid}.legend-item.label`}
                  variant={"body-sm"}
                  marginRight={0.5}
                >
                  {rowData.label}
                </DiscoText>
                {rowData.isCorrect && renderCorrectAnswerText()}
              </React.Fragment>,
              pluralize(
                isPassFail ? "submission" : memberLabel.singular,
                rowData.frequency,
                true
              ),
              `${rowData.percentage}%`,
            ]}
            renderTooltipData={renderTooltip}
            sortLegend={question.type !== "rating"}
          />
        </div>
      )
    return (
      <div className={classes.horizontalChart}>
        <DiscoSimpleHorizontalBarChart
          testid={`${testid}.bar-chart`}
          data={data}
          renderLabel={(labelData) => (
            <DiscoText testid={`${testid}.${labelData.label}`} variant={"body-sm"}>
              {question.type !== "rating" && `${labelData.letter}. `}
              {labelData.label}
              {labelData.isCorrect && renderCorrectAnswerText()}
            </DiscoText>
          )}
          renderTooltipContent={renderTooltip}
        />
      </div>
    )
  }

  function renderTooltip(tooltipData: Data) {
    return (
      <div className={classes.tooltipContent}>
        {tooltipData.isCorrect && (
          <DiscoIcon
            icon={"check"}
            height={24}
            width={24}
            color={theme.palette.groovy.green[600]}
            className={classes.check}
          />
        )}
        <div className={classes.tooltipText}>
          <DiscoText variant={"body-md-600"} color={"common.white"}>
            {tooltipData.label}
          </DiscoText>
          <DiscoText variant={"body-sm"} color={"common.white"}>
            {chartType === "pie"
              ? `${tooltipData.percentage}%`
              : `${pluralize(
                  isPassFail ? "submission" : memberLabel.singular,
                  tooltipData.frequency,
                  true
                )} selected - ${tooltipData.percentage}%`}
          </DiscoText>
        </div>
      </div>
    )
  }

  function renderCorrectAnswerText() {
    return (
      <DiscoText
        testid={`${testid}.legend-item.is-correct`}
        component={"i"}
        fontStyle={"italic"}
        variant={"body-sm-500"}
        color={"groovy.green.600"}
        marginLeft={0.5}
      >
        {"*Correct Answer"}
      </DiscoText>
    )
  }

  function getOptionLabel(label: string, index: number) {
    if (question.type === "rating")
      return `${ratingOptionDisplayNumber(index, options!.length)}${
        label ? `: ${label}` : ""
      }`
    return label
  }
}
const useStyles = makeUseStyles((theme) => ({
  item: {
    width: "100%",
    maxWidth: QUESTION_REPORT_MAX_WIDTH,
    padding: 0,
  },
  answerHeader: {
    marginBottom: 0,
    padding: theme.spacing(0, 2, 2),
  },
  questionSection: {
    margin: theme.spacing(2),
  },
  pieChart: {
    padding: theme.spacing(3),
  },
  horizontalChart: {
    padding: theme.spacing(3),
    display: "grid",
    placeItems: "center",
  },
  tooltipContent: {
    display: "flex",
    alignItems: "flex-start",
  },
  tooltipText: {
    display: "flex",
    flexDirection: "column",
  },
  check: {
    flex: "1 0 auto",
  },
  buttonSelected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: `1.5px solid ${theme.palette.primary.main} !important`,
    zIndex: theme.zIndex.raise2, // default for buttons group is raise1 on hover, so raise2 for selected
    "&:hover:not(:disabled, .Mui-disabled)": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      border: `1.5px solid ${theme.palette.primary.main}`,
      zIndex: theme.zIndex.raise2,
    },
  },
}))

export default QuizSubmissionsQuestionReportsListItem
