import { DiscoText } from "@disco-ui"
import DiscoProgressBar from "@disco-ui/progress/DiscoProgressBar"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  value: number
}

function CurriculumGenerationReferenceMeter(props: Props) {
  const { value } = props
  const theme = useTheme()

  return (
    <div>
      <DiscoProgressBar
        variant={"determinate"}
        percentage={value}
        color={getReferenceMeterColor(value)}
      />
      {getReferenceMeterDisplayText(value)}
    </div>
  )

  function getReferenceMeterDisplayText(referenceMeter: number) {
    if (referenceMeter === 0) {
      return (
        <>
          <DiscoText variant={"body-sm-700"} display={"inline"}>
            {"Start "}
          </DiscoText>
          <DiscoText variant={"body-sm"} display={"inline"}>
            {"by adding a prompt and references to build a good curriculum."}
          </DiscoText>
        </>
      )
    }

    if (referenceMeter >= 25 && referenceMeter < 50) {
      return (
        <>
          <DiscoText variant={"body-sm-700"} display={"inline"}>
            {"Good Start. "}
          </DiscoText>
          <DiscoText variant={"body-sm"} display={"inline"}>
            {"Try to give more details and add at least one reference."}
          </DiscoText>
        </>
      )
    }

    if (referenceMeter >= 50 && referenceMeter < 75) {
      return (
        <>
          <DiscoText variant={"body-sm-700"} display={"inline"}>
            {"You're on the right track! "}
          </DiscoText>
          <DiscoText variant={"body-sm"} display={"inline"}>
            {"Now, include a prompt to provide a clear direction for the curriculum."}
          </DiscoText>
        </>
      )
    }

    if (referenceMeter >= 75 && referenceMeter < 100) {
      return (
        <>
          <DiscoText variant={"body-sm-700"} display={"inline"}>
            {"Almost there! "}
          </DiscoText>
          <DiscoText variant={"body-sm"} display={"inline"}>
            {"More details and references will enchance your curriculum."}
          </DiscoText>
        </>
      )
    }

    return (
      <>
        <DiscoText variant={"body-sm-700"} display={"inline"}>
          {"Excellent! "}
        </DiscoText>
        <DiscoText variant={"body-sm"} display={"inline"}>
          {"Your description and references are spot on."}
        </DiscoText>
      </>
    )
  }

  function getReferenceMeterColor(referenceMeter: number): string {
    if (referenceMeter === 0) {
      return "neutral"
    }

    if (referenceMeter >= 25 && referenceMeter < 50) {
      return theme.palette.groovy.orange[300]
    }

    if (referenceMeter >= 50 && referenceMeter < 75) {
      return theme.palette.groovy.orange[200]
    }

    if (referenceMeter >= 75 && referenceMeter < 100) {
      return theme.palette.groovy.yellow[300]
    }

    return theme.palette.groovy.green[400]
  }
}

export default observer(CurriculumGenerationReferenceMeter)
