import useQuizEditorDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import { QuizSubmissionEditorFormMutation } from "@/content-usage/drawer/quizzes/submission-editor/__generated__/QuizSubmissionEditorFormMutation.graphql"
import { QuizSubmissionEditorFormState } from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditor"
import { QuizSubmissionResultSidebarQuery } from "@/content-usage/drawer/quizzes/submission-result/__generated__/QuizSubmissionResultSidebarQuery.graphql"
import QuizSubmissionIncompleteTile from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionIncompleteTile"
import { QuizSubmissionResultFilter } from "@/content-usage/drawer/quizzes/utils/quizSubmissionUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import WebFormResultCheckbox from "@/web-form/result/WebFormResultCheckbox"
import { webFormSubmissionsUtils_submissionUserFragment$data } from "@/web-form/utils/__generated__/webFormSubmissionsUtils_submissionUserFragment.graphql"
import {
  QuizAnswerData,
  QuizQuestionData,
  QuizResult,
  QuizSubmission,
  useWebFormContentUsageQuery,
} from "@/web-form/utils/webFormQueryUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoChip,
  DiscoDivider,
  DiscoIconButton,
  DiscoSelect,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoDrawerSidebar from "@disco-ui/drawer/DiscoDrawerSidebar"
import { ArrayUtils, range } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import pluralize from "pluralize"
import { graphql, useLazyLoadQuery } from "react-relay"

interface QuizSubmissionResultSidebarProps extends TestIDProps {
  form?: FormStore<QuizSubmissionEditorFormState, QuizSubmissionEditorFormMutation>
  contentLabel: string
  answers: (QuizAnswerData & { question: QuizQuestionData })[]
  currentQuestionId: GlobalID | null
  goToQuestion: (webFormQuestionId: GlobalID) => void
  filter: QuizSubmissionResultFilter
  setFilter: React.Dispatch<React.SetStateAction<QuizSubmissionResultFilter>>
  user?: webFormSubmissionsUtils_submissionUserFragment$data["user"]
  result?: QuizResult
  submission?: QuizSubmission
  contentUsageId?: GlobalID
  revisionId?: GlobalID
  moreOptions?: React.ReactNode
  toolbarState?: WebFormSubmissionsUtils.ToolbarState
  toolbarHandlers?: Pick<
    ReturnType<typeof WebFormSubmissionsUtils.useToolbarState>,
    "handleAddSubmissionIds"
  >
}

export const QUIZ_SUBMISSION_SIDEBAR_WIDTH = 320

function QuizSubmissionResultSidebar({
  testid = "QuizSubmissionResultSidebar",
  form,
  contentLabel = "quiz",
  result,
  submission,
  contentUsageId,
  revisionId,
  answers,
  currentQuestionId,
  goToQuestion,
  filter,
  setFilter,
  /** provide a user if the submission is someone other than the person viewing it */
  user,
  moreOptions,
  toolbarState,
  toolbarHandlers,
}: QuizSubmissionResultSidebarProps) {
  const userTimezone = useUserTimezone()
  const { goToRetake } = useQuizEditorDrawer()
  const activeOrganization = useActiveOrganization()
  const isMobile = useIsMobile()
  const classes = useStyles()
  const drawer = useGlobalDrawer("contentUsage")
  const { closeDrawerSidebar } = useDrawerContext()
  const correctAnswers = result?.correctAnswers?.length ?? 0
  const incorrectAnswers = result?.incorrectAnswers?.length ?? 0
  const contentUsage = useWebFormContentUsageQuery({ usageId: contentUsageId || "" })
  const isPassFail = typeof contentUsage?.passPercentage === "number"

  const { revision } = useLazyLoadQuery<QuizSubmissionResultSidebarQuery>(
    graphql`
      query QuizSubmissionResultSidebarQuery(
        $contentUsageId: ID!
        $revisionId: ID!
        $userIds: [ID!]!
        $orderBy: WebFormSubmissionsOrderByInput
      ) {
        revision: node(id: $revisionId) {
          ... on WebFormRevision {
            id
            submissions(
              userIds: $userIds
              contentUsageId: $contentUsageId
              orderBy: $orderBy
            ) {
              edges {
                node {
                  id
                  completedAt
                  hasPassed
                  organizationMembershipId
                  result {
                    percentage
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      contentUsageId: contentUsageId || "",
      revisionId: revisionId || "",
      userIds: user ? [user.id] : [],
      orderBy: isPassFail ? { field: "created_at", direction: "ASC" } : undefined,
    },
    { fetchPolicy: "store-and-network" }
  )

  const submitter = user?.fullName ?? "You"
  const submissions = Relay.connectionToArray(revision?.submissions)
  const submissionsById = ArrayUtils.mapBy(submissions, "id")
  const highestGrade = Math.max(...submissions.map((s) => s.result?.percentage || 0))
  const isComplete = Boolean(
    submissionsById[drawer.params.submissionId || ""]?.completedAt
  )

  return (
    <DiscoDrawerSidebar testid={testid} classes={{ sidebar: classes.sideBar }}>
      {/* add a placeholder so the DiscoDrawerHeader button doesn't render over the sidebar header here */}
      {isMobile && <div />}
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <DiscoText
            testid={`${testid}.header-title`}
            variant={"body-sm-600"}
          >{`${contentLabel} Result`}</DiscoText>

          <div className={classes.rhsHeader}>
            {isPassFail && (
              <DiscoChip
                color={submission?.hasPassed ? "green" : isComplete ? "red" : "yellow"}
                label={
                  isComplete
                    ? submission?.hasPassed
                      ? "Passed"
                      : "Failed"
                    : "Incomplete"
                }
              />
            )}

            {moreOptions && (
              <div className={classes.headerMoreActions}>{moreOptions}</div>
            )}
          </div>
        </div>
        {result ? (
          isPassFail ? (
            <>
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {`${submitter} ${
                  submission?.hasPassed ? "passed" : "failed"
                } this ${contentLabel} on ${getCompletedAt()}`}
              </DiscoText>

              <div className={classes.passFailSummary}>
                <div className={classes.passFailSummaryText}>
                  <DiscoText
                    testid={`${testid}.highest-grade`}
                    variant={"heading-sm"}
                    className={classes.text}
                  >{`${highestGrade}%`}</DiscoText>
                  <DiscoText
                    variant={"body-sm"}
                    color={"text.secondary"}
                    className={classes.text}
                  >
                    {"Highest Grade"}
                  </DiscoText>
                </div>

                <div className={classes.passFailSummaryText}>
                  <DiscoText
                    testid={`${testid}.attempts`}
                    variant={"heading-sm"}
                    className={classes.text}
                  >
                    {submissions.length}
                  </DiscoText>
                  <DiscoText
                    variant={"body-sm"}
                    color={"text.secondary"}
                    className={classes.text}
                  >
                    {pluralize("Attempt", submissions.length)}
                  </DiscoText>
                </div>
              </div>

              {isViewerSubmission() && (
                <DiscoButton
                  testid={`${testid}.retake`}
                  onClick={handleRetake}
                >{`Re-Take ${contentLabel}`}</DiscoButton>
              )}
            </>
          ) : (
            <>
              <DiscoText
                variant={"body-sm"}
                color={"text.secondary"}
              >{`${submitter} completed this ${contentLabel} with a result of:`}</DiscoText>
              <DiscoText
                testid={`${testid}.percentage`}
                variant={"heading-xl"}
                color={"primary.main"}
                align={"center"}
                marginLeft={1.5}
                marginRight={1.5}
                marginTop={1}
                marginBottom={1}
              >{`${result.percentage}%`}</DiscoText>
            </>
          )
        ) : (
          <>
            <DiscoText
              variant={"body-sm"}
              color={"text.secondary"}
            >{`Results will display here when the submission is complete.`}</DiscoText>
            <QuizSubmissionIncompleteTile testid={`${testid}.incomplete-submission`} />
          </>
        )}
      </div>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <ScrollShadowContainer classes={{ scrollContainer: classes.content }}>
        {Boolean(result || isPassFail) && (
          <div className={classes.resultSummaryContainer}>
            <DiscoText variant={"body-sm-600"}>{"Submission Summary"}</DiscoText>

            {/* Attempts */}
            {isPassFail && (
              <div className={classes.attemptsSection}>
                <DiscoSelect
                  testid={`${testid}.attempts`}
                  disableClearable
                  autoComplete={false}
                  className={classes.attemptsDropdown}
                  classes={{
                    root: classes.attemptText,
                  }}
                  value={drawer.params.submissionId || ""}
                  options={submissions.map((s, i) => ({
                    value: s.id,
                    title: `Attempt ${i + 1}`,
                    // Users should not be able to select an attempt that is in progress
                    disabled: isViewerSubmission() && !s.completedAt,
                  }))}
                  onChange={(submissionId) => {
                    if (!submissionId) return

                    // If viewing a single submission by filtering,
                    // add the new submission to the list
                    if (toolbarState?.submissionIds?.length) {
                      toolbarHandlers?.handleAddSubmissionIds([
                        ...(toolbarState.submissionIds || []),
                        submissionId,
                      ])
                    }

                    // Redirect to the new submission
                    drawer.setParams({ submissionId })
                  }}
                  renderOption={(option) => {
                    const optionSubmission = submissionsById[option.value]
                    const score = optionSubmission?.result?.percentage || 0
                    const optionPassed = optionSubmission.hasPassed
                    const optionComplete = Boolean(optionSubmission?.completedAt)

                    return (
                      <div className={classes.attemptOption}>
                        <DiscoText variant={"body-sm-500"}>{option.title}</DiscoText>
                        <DiscoChip
                          color={
                            optionPassed ? "green" : optionComplete ? "red" : "yellow"
                          }
                          label={optionComplete ? `${score}%` : "Incomplete"}
                        />
                      </div>
                    )
                  }}
                />
              </div>
            )}

            {!!correctAnswers && result && (
              <div className={classes.scoreItem}>
                <WebFormResultCheckbox
                  isSelected
                  isCorrectOption
                  variant={"transparent"}
                />
                <DiscoText
                  testid={`${testid}.correct-answers`}
                  variant={"body-sm"}
                  color={"groovy.green.600"}
                >{`${correctAnswers} correct ${pluralize(
                  "answer",
                  correctAnswers
                )}`}</DiscoText>
              </div>
            )}

            {!!incorrectAnswers && result && (
              <div className={classes.scoreItem}>
                <WebFormResultCheckbox
                  isSelected
                  isCorrectOption={false}
                  variant={"transparent"}
                />
                <DiscoText
                  testid={`${testid}.incorrect-answers`}
                  variant={"body-sm"}
                  color={"groovy.red.600"}
                >{`${incorrectAnswers} incorrect ${pluralize(
                  "answer",
                  incorrectAnswers
                )}`}</DiscoText>
              </div>
            )}
          </div>
        )}

        <DiscoText
          variant={"body-sm"}
          color={"text.secondary"}
          marginTop={2.5}
          marginBottom={2.5}
        >
          {"Click to navigate to a specific question"}
        </DiscoText>

        <div className={classes.answerGrid}>
          {answers.map((a) => (
            <DiscoIconButton
              key={a.id}
              onClick={() => {
                goToQuestion(a.webFormQuestionId)
                if (isMobile) closeDrawerSidebar()
              }}
              className={classNames(classes.answerGridItem, {
                [classes.answerGridItemSelected]:
                  a.webFormQuestionId === currentQuestionId,
              })}
            >
              <DiscoText
                color={
                  a.isCorrect
                    ? "text.primary"
                    : a.isSkipped && !result // if the submission is incomplete, indicate a skipped question with warning yellow
                    ? "groovy.yellow.500"
                    : "text.danger"
                }
              >
                {a.question.ordering + 1}
              </DiscoText>
            </DiscoIconButton>
          ))}
        </div>
      </ScrollShadowContainer>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <div className={classes.footer}>
        <DiscoSelect
          testid={`${testid}.filter`}
          autoWidth
          disableClearable
          autoComplete={false}
          className={classes.select}
          value={filter}
          customSelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            },
          }}
          options={[
            { value: "all", title: "View all questions" },
            ...ArrayUtils.spreadIf(
              [
                {
                  value: "correct" as QuizSubmissionResultFilter,
                  title: "Questions with correct answer",
                },
                {
                  value: "incorrect" as QuizSubmissionResultFilter,
                  title: "Questions with incorrect answer",
                },
              ],
              result
            ),
            ...ArrayUtils.spreadIf(
              [
                {
                  value: "complete" as QuizSubmissionResultFilter,
                  title: "Questions with an answer",
                },
                {
                  value: "incomplete" as QuizSubmissionResultFilter,
                  title: "Questions without an answer",
                },
              ],
              !result
            ),
          ]}
          onChange={(v) => setFilter(v as QuizSubmissionResultFilter)}
        />
      </div>
    </DiscoDrawerSidebar>
  )

  function isViewerSubmission() {
    if (!drawer.params.submissionId) return false
    const s = submissionsById[drawer.params.submissionId]
    return s?.organizationMembershipId === activeOrganization?.viewerMembership?.id
  }

  function getCompletedAt() {
    if (!drawer.params.submissionId) return ""
    const s = submissionsById[drawer.params.submissionId]
    if (!s?.completedAt) return ""

    return formatDateWithOptions({
      timeZone: userTimezone,
      format: DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_AT,
    })(new Date(s?.completedAt))
  }

  function handleRetake() {
    // Reset the submission form
    WebFormSubmissionsUtils.resetSubmissionForm(form)

    // Navigate to the questions
    goToRetake()
  }
}

const useStyles = makeUseStyles((theme) => ({
  sideBar: {
    display: "grid",
    overflow: "hidden",
    height: "100%",
    width: "100%",
    maxWidth: QUIZ_SUBMISSION_SIDEBAR_WIDTH,
    gridTemplateRows: "auto auto 1fr auto auto",
    justifyItems: "center",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      // in mobile, use the full width of the DiscoDrawerHeader and only restrict inner content width
      maxWidth: "unset",
      // button height + top spacing in DiscoDrawerHeader
      gridTemplateRows: `${theme.spacing(3 + 6)}px auto auto 1fr auto auto`,
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5, 2.5),
    width: "100%",
    maxWidth: QUIZ_SUBMISSION_SIDEBAR_WIDTH,
  },
  headerTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(1.5),
    flexWrap: "wrap-reverse",
  },
  rhsHeader: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  headerMoreActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  resultSummaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  passFailSummary: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
    width: "100%",
  },
  passFailSummaryText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  text: {
    width: "100%",
    textAlign: "left",
  },
  attemptsSection: {
    margin: theme.spacing(1, 0),
  },
  attemptsDropdown: {
    width: "100%",
  },
  attemptText: {
    ...theme.typography["body-sm"],
    fontWeight: 500,
  },
  attemptOption: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  scoreItem: {
    display: "flex",
    gap: theme.spacing(0.5),
    flexWrap: "nowrap",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5, 2.5),
    width: "100%",
    maxWidth: QUIZ_SUBMISSION_SIDEBAR_WIDTH,
  },
  answerGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridAutoRows: "1fr",
    gap: theme.spacing(1.5, 1.5),
  },
  answerGridItem: {
    height: "36px",
    width: "36px",
    aspectRatio: 1,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
  answerGridItemSelected: {
    outline: `1.5px solid ${theme.palette.primary.main}`,
  },
  select: {
    width: "100%",
  },
  footer: {
    padding: theme.spacing(1.5, 2.5),
    width: "100%",
    maxWidth: QUIZ_SUBMISSION_SIDEBAR_WIDTH,
  },
}))

export function QuizSubmissionResultSidebarSkeleton() {
  const classes = useStyles()
  return (
    <DiscoDrawerSidebar classes={{ sidebar: classes.sideBar }}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <DiscoTextSkeleton variant={"body-sm-500"} width={"50%"} />
          <DiscoButtonSkeleton width={"40px"} />
        </div>
        <DiscoTextSkeleton variant={"body-sm"} color={"text.secondary"} width={"100%"} />
        <DiscoTextSkeleton
          variant={"heading-xl"}
          color={"primary.main"}
          width={"100px"}
        />
      </div>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <ScrollShadowContainer classes={{ scrollContainer: classes.content }}>
        <div className={classes.resultSummaryContainer}>
          <DiscoText variant={"body-sm-500"}>{"Submission Summary"}</DiscoText>

          <DiscoTextSkeleton
            variant={"body-sm"}
            color={"text.secondary"}
            width={"100%"}
          />

          <div className={classes.scoreItem}>
            <WebFormResultCheckbox isSelected isCorrectOption variant={"transparent"} />
            <DiscoTextSkeleton
              variant={"body-sm"}
              color={"groovy.green.600"}
              width={"100%"}
            />
          </div>

          <div className={classes.scoreItem}>
            <WebFormResultCheckbox
              isSelected
              isCorrectOption={false}
              variant={"transparent"}
            />
            <DiscoTextSkeleton
              variant={"body-sm"}
              color={"groovy.red.600"}
              width={"100%"}
            />
          </div>
        </div>

        <DiscoText
          variant={"body-sm"}
          color={"text.secondary"}
          marginTop={2.5}
          marginBottom={2.5}
        >
          {"Click to navigate to a specific question"}
        </DiscoText>

        <div className={classes.answerGrid}>
          {range(10).map((i) => (
            <DiscoButtonSkeleton key={i} />
          ))}
        </div>
      </ScrollShadowContainer>
      <DiscoDivider marginBottom={0} marginTop={0} />
      <div className={classes.footer}>
        <DiscoSelect
          autoWidth
          disableClearable
          autoComplete={false}
          className={classes.select}
          value={"all"}
          options={[{ value: "all", title: "View all questions" }]}
          onChange={() => null}
        />
      </div>
    </DiscoDrawerSidebar>
  )
}

export default Relay.withSkeleton({
  component: QuizSubmissionResultSidebar,
  skeleton: QuizSubmissionResultSidebarSkeleton,
})
