import { InlineContentDrawerMode } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import ContentCTABannerTemplate, {
  ContentCTABannerTemplateSkeleton,
} from "@/content-usage/drawer/quizzes/ContentCTABannerTemplate"
import { QuizEditorBannerModuleUsageQuery } from "@/content-usage/drawer/quizzes/__generated__/QuizEditorBannerModuleUsageQuery.graphql"
import { QuizEditorBanner_ContentFragment$key } from "@/content-usage/drawer/quizzes/__generated__/QuizEditorBanner_ContentFragment.graphql"
import { QuizEditorBanner_ContentUsageFragment$key } from "@/content-usage/drawer/quizzes/__generated__/QuizEditorBanner_ContentUsageFragment.graphql"
import useQuizEditorDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import Relay from "@/relay/relayUtils"
import { RequireOnlyOne } from "@/types/util/RequireOnlyOne"
import { useWebFormSubmissionsQuery } from "@/web-form/utils/webFormQueryUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AITextButton from "@components/ai/AITextButton"
import WithEntitlement from "@components/entitlement/WithEntitlement"
import { DiscoButton, DiscoText, DiscoTooltip } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type QuizEditorBannerProps = TestIDProps &
  RequireOnlyOne<
    {
      mode: InlineContentDrawerMode
      contentUsageKey?: QuizEditorBanner_ContentUsageFragment$key | null
      contentKey?: QuizEditorBanner_ContentFragment$key | null
      usageSubmissionsCount?: number
      contentLabel: string
      form: ContentFormStore
    },
    "contentUsageKey" | "contentKey"
  >

function QuizEditorBanner({
  testid = "QuizEditorBanner",
  mode,
  contentUsageKey,
  contentKey,
  usageSubmissionsCount,
  contentLabel,
  form,
}: QuizEditorBannerProps) {
  const activeOrganization = useActiveOrganization()
  const { goToQuestions, goToQuestionsEditor, goToAiEditor, goToSubmissions, drawer } =
    useQuizEditorDrawer()
  const memberLabel = useLabel("admin_member")

  const contentUsageDrawer = useContentUsageDrawer()
  const classes = useStyles()

  const contentUsage = useFragment<QuizEditorBanner_ContentUsageFragment$key>(
    graphql`
      fragment QuizEditorBanner_ContentUsageFragment on ContentUsage {
        id
        content {
          type
          webForm {
            id
            currentRevision {
              id
            }
          }
        }
        ...usePermissionsFragment
      }
    `,
    contentUsageKey ?? null
  )

  const { usage } = useLazyLoadQuery<QuizEditorBannerModuleUsageQuery>(
    graphql`
      query QuizEditorBannerModuleUsageQuery($id: ID!) {
        usage: node(id: $id) {
          id
          ... on ContentUsage {
            id
            canGenerateQuizForModule
          }
        }
      }
    `,
    {
      id: contentUsageDrawer.params.drawerModuleContentUsageId || "",
    },
    {
      fetchPolicy: "network-only",
    }
  )

  const content = useFragment<QuizEditorBanner_ContentFragment$key>(
    graphql`
      fragment QuizEditorBanner_ContentFragment on Content {
        type
        webForm {
          id
        }
      }
    `,
    contentKey ?? null
  )

  const permissions = usePermissions(contentUsage ?? activeOrganization)
  const hasManagePermissions = permissions.has("content.manage")

  const allSubmissions = WebFormSubmissionsUtils.useCount({
    webFormId: contentUsage?.content?.webForm?.id || content?.webForm?.id,
    contentUsageId: contentUsage?.id,
    include: mode === "edit",
    excludeUsageScope: true,
  })
  // If editing for all usages we can use the allSubmissions count to validate if we can edit in both cases
  const usageHasSubmissions = !!usageSubmissionsCount
  const hasSubmissions = usageHasSubmissions || !!allSubmissions
  const canGenerateQuizWithAi = Boolean(usage?.canGenerateQuizForModule)

  // Get the admin's existing submission if this is a survey content usage drawer, since
  // admins can create submissions for surveys, unlike quizzes
  const isSurvey = contentUsage?.content?.type === "survey" || content?.type === "survey"
  const { submissions } = useWebFormSubmissionsQuery({
    include: isSurvey,
    revisionId: contentUsage?.content?.webForm?.currentRevision.id,
    usageId: contentUsage?.id,
    first: 1,
  })
  const { submission = null } = submissions.length > 0 ? submissions[0] : {}

  if (!hasManagePermissions && mode === "edit") return null

  return (
    <ContentCTABannerTemplate
      testid={testid}
      message={renderMessage()}
      buttons={renderButtons()}
    />
  )

  function renderMessage() {
    const copy: {
      [K in "usageSubmissions" | "otherSubmissions" | "noSubmissions" | "initialSetup"]: {
        title: string
        body: string
      }
    } = {
      usageSubmissions: {
        title: `${pluralize(
          memberLabel.singular,
          usageSubmissionsCount,
          true
        )} ${pluralize(
          "have",
          usageSubmissionsCount
        )} submitted answers for this ${contentLabel}.`,
        body: `You can no longer edit the ${contentLabel} questions because ${memberLabel.plural} have started submitting answers.`,
      },
      otherSubmissions: {
        title: `There are submissions for other uses of this ${contentLabel}`,
        body: `To edit the questions, you will need to duplicate the ${contentLabel}.`,
      },
      noSubmissions: {
        title: `Edit ${contentLabel} Questions`,
        body: `Click the button to edit the questions of this ${contentLabel}.`,
      },
      initialSetup: {
        title: `📌 Set Up ${contentLabel} Questions`,
        body: `Once you're ready, click the button to start adding questions to the ${contentLabel}.`,
      },
    }

    let submissionsCase: keyof typeof copy = "noSubmissions"
    if (mode === "add" && !form.changedState.content?.webFormRevision?.questions.length)
      submissionsCase = "initialSetup"
    if (hasSubmissions) submissionsCase = "otherSubmissions"
    if (usageHasSubmissions) submissionsCase = "usageSubmissions"

    return (
      <>
        <DiscoText testid={`${testid}.title.${submissionsCase}`} variant={"body-md-600"}>
          {copy[submissionsCase].title}
        </DiscoText>
        <DiscoText testid={`${testid}.body.${submissionsCase}`}>
          {copy[submissionsCase].body}
        </DiscoText>
      </>
    )
  }

  function renderButtons() {
    const startSubmissionButton = drawer?.kind === "contentUsage" &&
      isSurvey &&
      !submission?.completedAt && (
        <DiscoButton
          testid={`${testid}.go-to-questions`}
          onClick={goToQuestions}
          color={"grey"}
          variant={"outlined"}
        >
          {submission ? `Resume ${contentLabel}` : `Start ${contentLabel}`}
        </DiscoButton>
      )

    if (mode === "add" && !form.changedState.content?.webFormRevision?.questions.length)
      return (
        <>
          {canGenerateQuizWithAi && (
            <WithEntitlement entitlement={"ai_content_generation"}>
              {({ hasEntitlement }) => (
                <DiscoTooltip
                  content={
                    "Use Disco AI to generate questions based on existing items in the current module."
                  }
                  placement={"bottom"}
                  disabled={!hasEntitlement}
                >
                  <span>
                    <AITextButton
                      className={classes.aiButton}
                      disabled={!hasEntitlement}
                      leftIcon={"stars"}
                      onClick={handleOpenAiQuizEditor}
                    >
                      {"Generate Questions"}
                    </AITextButton>
                  </span>
                </DiscoTooltip>
              )}
            </WithEntitlement>
          )}
          <DiscoButton
            testid={`${testid}.setup-questions`}
            onClick={goToQuestionsEditor}
            color={"grey"}
            variant={"outlined"}
          >
            {"Set Up Questions"}
          </DiscoButton>
        </>
      )
    if (!hasSubmissions)
      return (
        <>
          {startSubmissionButton}
          <DiscoButton
            testid={`${testid}.edit-questions`}
            onClick={goToQuestionsEditor}
            color={"grey"}
            variant={"outlined"}
          >
            {"Edit Questions"}
          </DiscoButton>
        </>
      )
    return (
      <>
        {startSubmissionButton}
        {usageHasSubmissions ? (
          <DiscoButton
            testid={`${testid}.view-results`}
            color={"grey"}
            variant={"outlined"}
            onClick={() => goToSubmissions("submissions-overview")}
          >
            {"View Results"}
          </DiscoButton>
        ) : (
          <DiscoButton
            testid={`${testid}.view-questions`}
            onClick={goToQuestionsEditor}
            color={"grey"}
            variant={"outlined"}
          >
            {"View Questions"}
          </DiscoButton>
        )}
      </>
    )
  }

  function handleOpenAiQuizEditor() {
    form.state.content.isAiGenerated = true
    goToAiEditor()
  }
}

const useStyles = makeUseStyles((theme) => ({
  aiButton: {
    padding: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  skeleton: () => <ContentCTABannerTemplateSkeleton />,
  component: QuizEditorBanner,
})
