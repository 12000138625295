import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { TestIDProps } from "@utils/typeUtils"
import { Fragment } from "react"

type Props = TestIDProps & {
  toolbarState: WebFormSubmissionsUtils.ToolbarState
  handleFilterBy: WebFormSubmissionsUtils.ToolbarStateHandlers["handleFilterBy"]
  showResult: boolean
  isPassFail?: boolean
}

function QuizSubmissionsFilterOptions(props: Props) {
  const {
    testid = "QuizSubmissionsFilterOptions",
    toolbarState,
    handleFilterBy,
    showResult,
    isPassFail = false,
  } = props

  return (
    <>
      {WebFormSubmissionsUtils.getFilterByOptions({ isPassFail }).map((option) => {
        if (!showResult && option.value === "result") {
          return <Fragment key={option.value} />
        }

        return (
          <DiscoTableFilterDropdown
            key={option.value}
            testid={`${testid}.filter.option-${option.value}`}
            isNested
            options={option.options}
            onOptionSelect={(o) =>
              handleFilterBy({
                value: option.value,
                option: o,
              })
            }
            selectedOption={toolbarState.filterBy[option.value]}
            dropdown={{
              label: option.title,
            }}
          />
        )
      })}
    </>
  )
}

export default QuizSubmissionsFilterOptions
