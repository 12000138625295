import { QuizSubmissionsDrawerContentProps } from "@/content-usage/drawer/ContentUsageQuizSubmissionsDrawerContent"
import { ContentType } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import QuizSubmissionsOverviewContentHeader, {
  QuizSubmissionsOverviewContentHeaderSkeleton,
} from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsOverviewContentHeader"
import QuizSubmissionsTable, {
  QuizSubmissionsTableSkeleton,
} from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsTable"
import { QuizSubmissionsOverviewContentQuery } from "@/content-usage/drawer/quizzes/review-submissions/__generated__/QuizSubmissionsOverviewContentQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface QuizSubmissionsOverviewContentProps
  extends TestIDProps,
    QuizSubmissionsDrawerContentProps {
  contentLabel: string
  contentType: ContentType
}

function QuizSubmissionsOverviewContent({
  testid = "QuizSubmissionsOverviewContent",
  contentLabel,
  contentUsageId,
  revision,
  contentType,
}: QuizSubmissionsOverviewContentProps) {
  const classes = useStyles()

  const { contentUsage } = useLazyLoadQuery<QuizSubmissionsOverviewContentQuery>(
    graphql`
      query QuizSubmissionsOverviewContentQuery($id: ID!) {
        contentUsage: node(id: $id) {
          ... on ContentUsage {
            ...QuizSubmissionsOverviewContentHeader_ContentUsageFragment
            ...QuizSubmissionsTable_ContentUsageFragment
          }
        }
      }
    `,
    { id: contentUsageId }
  )

  return (
    <div data-testid={`${testid}.container`} className={classes.container}>
      <QuizSubmissionsOverviewContentHeader
        testid={testid}
        contentLabel={contentLabel}
        contentUsageId={contentUsageId}
        webFormId={revision.webFormId}
        contentType={contentType}
        contentUsageKey={contentUsage}
      />
      <QuizSubmissionsTable
        testid={`${testid}.table`}
        contentUsageId={contentUsageId}
        revision={revision}
        contentType={contentType}
        contentUsageKey={contentUsage}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    width: "100%",
    maxWidth: "940px",
    margin: "0 auto",
    padding: theme.spacing(2, 2.5),
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
}))

function QuizSubmissionsOverviewContentSkeleton({
  contentType,
}: QuizSubmissionsOverviewContentProps) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <QuizSubmissionsOverviewContentHeaderSkeleton />
      <QuizSubmissionsTableSkeleton contentType={contentType} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: QuizSubmissionsOverviewContent,
  skeleton: QuizSubmissionsOverviewContentSkeleton,
})
