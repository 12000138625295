import { CurriculumConfirmationModalConfirmationModalFragment$key } from "@/content-usage/ modals/__generated__/CurriculumConfirmationModalConfirmationModalFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { ModalProps } from "@components/modal/Modal"
import DiscoCheckToConfirmModal from "@disco-ui/modal/DiscoCheckToConfirmModal"
import { useTheme } from "@material-ui/core"
import { graphql, useFragment } from "react-relay"

interface CurriculumConfirmationModalProps
  extends Pick<ModalProps, "onClose" | "isOpen"> {
  content: CurriculumConfirmationModalConfirmationModalFragment$key
  onConfirm: () => void
  isLoading?: boolean
}

export default function CurriculumConfirmationModal({
  content,
  isOpen,
  onClose,
  onConfirm,
  isLoading = false,
}: CurriculumConfirmationModalProps) {
  const classes = useStyles()
  const theme = useTheme()

  const contentConfirmation =
    useFragment<CurriculumConfirmationModalConfirmationModalFragment$key>(
      graphql`
        fragment CurriculumConfirmationModalConfirmationModalFragment on Content {
          name
          richEditorDescriptionContent
          ctaText
        }
      `,
      content
    )

  if (!contentConfirmation) return null

  return (
    <DiscoCheckToConfirmModal
      title={contentConfirmation.name!}
      description={
        <DiscoEditor
          className={classes.contentEditable}
          defaultValue={contentConfirmation.richEditorDescriptionContent!}
          readOnly
          disableGutter
          textColor={theme.palette.groovy.neutral[500]}
        />
      }
      testid={"CurriculumConfirmationModal"}
      isOpen={isOpen}
      isConfirmation={true}
      modalContentLabel={"Curriculum Confirmation Modal"}
      onClose={onClose}
      cancelButtonText={null}
      confirmationButtonProps={{
        children: contentConfirmation.ctaText!,
        onClick: onConfirm,
        shouldDisplaySpinner: isLoading,
      }}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  contentEditable: {
    paddingTop: theme.spacing(0.5),
  },
}))
