import AIFormUploadedReferencesListItem from "@/content/ai/AIFormUploadedReferencesListItem"
import { GenerateCurriculumWithAIFormState } from "@/content/ai/button/GenerateCurriculumWithAIButton"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoSection, DiscoText } from "@disco-ui"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  form: FormStore<GenerateCurriculumWithAIFormState>
  onUploadedAssetRemove: (media: MediaResult) => void
}

function AIFormUploadedReferencesList(props: Props) {
  const { form, onUploadedAssetRemove } = props
  const theme = useTheme()
  const classes = useStyles()

  if (form.state.uploadedAssets.length === 0) return null

  return (
    <DiscoSection padding={1.5} className={classes.root}>
      <div className={classes.titleContainer}>
        <DiscoText variant={"body-md-600"} display={"inline"} marginRight={1}>
          {"Uploaded Sources"}
        </DiscoText>

        <DiscoTag
          testid={"CreateMembershipPlanModal.step-tag"}
          name={`${form.state.uploadedAssets.length}/5`}
          color={theme.palette.groovy.blue[400]}
          backgroundColor={theme.palette.groovy.blue[100]}
        />
      </div>

      {form.state.uploadedAssets.map((asset) => (
        <AIFormUploadedReferencesListItem
          key={asset.id}
          media={asset}
          onRemove={onUploadedAssetRemove}
        />
      ))}
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  titleContainer: {
    marginBottom: theme.spacing(1),
  },
  root: {
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
  },
}))

export default observer(AIFormUploadedReferencesList)
