/**
 * @generated SignedSource<<6d8cc6904692fe8d6b21e0b0c315fb11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizSubmissionsOverviewContentQuery$variables = {
  id: string;
};
export type QuizSubmissionsOverviewContentQuery$data = {
  readonly contentUsage: {
    readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionsOverviewContentHeader_ContentUsageFragment" | "QuizSubmissionsTable_ContentUsageFragment">;
  } | null;
};
export type QuizSubmissionsOverviewContentQuery = {
  variables: QuizSubmissionsOverviewContentQuery$variables;
  response: QuizSubmissionsOverviewContentQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSubmissionsOverviewContentQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QuizSubmissionsOverviewContentHeader_ContentUsageFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QuizSubmissionsTable_ContentUsageFragment"
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuizSubmissionsOverviewContentQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "passPercentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "roles",
                        "value": [
                          "member"
                        ]
                      }
                    ],
                    "concreteType": "ProductMembershipNodeConnection",
                    "kind": "LinkedField",
                    "name": "productMemberships",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "productMemberships(roles:[\"member\"])"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20b38f444c45a59808b19eb7b1abef94",
    "id": null,
    "metadata": {},
    "name": "QuizSubmissionsOverviewContentQuery",
    "operationKind": "query",
    "text": "query QuizSubmissionsOverviewContentQuery(\n  $id: ID!\n) {\n  contentUsage: node(id: $id) {\n    __typename\n    ... on ContentUsage {\n      ...QuizSubmissionsOverviewContentHeader_ContentUsageFragment\n      ...QuizSubmissionsTable_ContentUsageFragment\n    }\n    id\n  }\n}\n\nfragment QuizSubmissionsOverviewContentHeader_ContentUsageFragment on ContentUsage {\n  passPercentage\n  product {\n    productMemberships(roles: [member]) {\n      totalCount\n    }\n    id\n  }\n}\n\nfragment QuizSubmissionsTable_ContentUsageFragment on ContentUsage {\n  passPercentage\n}\n"
  }
};
})();

(node as any).hash = "103fe5a719112a8a4d2941a7c7cc2edd";

export default node;
