import QuizSubmissionResultAnswerListItem from "@/content-usage/drawer/quizzes/submission-result/QuizSubmissionResultAnswerListItem"
import { QuizSubmissionUtils } from "@/content-usage/drawer/quizzes/utils/quizSubmissionUtils"
import Clipboard from "@/core/ui/images/empty-state/clipboard.svg"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { WEB_FORM_QUESTIONS_MAX_WIDTH } from "@/web-form/utils/webFormConstants"
import {
  QuizAnswerData,
  QuizQuestionData,
  QuizRevision,
  QuizSubmission,
} from "@/web-form/utils/webFormQueryUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { range } from "lodash"
import { observer } from "mobx-react-lite"

interface QuizSubmissionResultAnswerListProps extends TestIDProps {
  revision: QuizRevision
  submission: QuizSubmission
  answers: (QuizAnswerData & { question: QuizQuestionData })[]
  currentQuestionId: GlobalID | null
  handleRemoveQuestionId: VoidFunction
  mode?: QuizSubmissionUtils.Mode
}

function QuizSubmissionResultAnswerList({
  testid = "QuizSubmissionResultAnswerList",
  revision,
  submission,
  answers,
  currentQuestionId,
  handleRemoveQuestionId,
  mode = "submitter",
}: QuizSubmissionResultAnswerListProps) {
  const totalQuestionsCount = Relay.connectionToArray(revision?.questions).length
  const classes = useStyles()

  return (
    <div data-testid={testid} className={classes.container}>
      {answers.length === 0 ? (
        <DiscoEmptyState
          testid={`${testid}.empty-state`}
          icon={<Clipboard width={"157px"} height={"151px"} />}
          title={"It's empty here!"}
          subtitle={"There are no questions for the selected filter"}
        />
      ) : (
        answers.map((answer, i) => {
          return (
            <QuizSubmissionResultAnswerListItem
              key={answer.id}
              testid={testid}
              itemIndex={i}
              readOnly
              scrollTo={currentQuestionId === answer.question.id}
              onBlur={handleRemoveQuestionId}
              totalQuestionsCount={totalQuestionsCount}
              answer={answer}
              showNotAnswered={mode === "reviewer" && answer.isSkipped}
              showCorrectAnswer={showCorrectAnswer()}
            />
          )
        })
      )}
    </div>
  )

  function showCorrectAnswer() {
    // Only show the correct answer if the quiz is not pass/fail and the submission has passed
    if (submission?.hasPassed === null) return true
    if (submission?.hasPassed === true) return true
    return false
  }
}

function ComponentSkeleton() {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {range(4).map((i) => (
        <Skeleton
          key={i}
          variant={"rect"}
          style={{
            width: "100%",
            height: "300px",
            borderRadius: theme.measure.borderRadius.medium,
            maxWidth: WEB_FORM_QUESTIONS_MAX_WIDTH,
          }}
        />
      ))}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    height: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.default,
  },
}))

QuizSubmissionResultAnswerList.Skeleton = ComponentSkeleton
export default observer(QuizSubmissionResultAnswerList)
