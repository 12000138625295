/**
 * @generated SignedSource<<7f9892576f68ef3ae4acd3eff96a2c03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizSubmissionsOverviewContentHeader_ContentUsageFragment$data = {
  readonly passPercentage: number | null;
  readonly product: {
    readonly productMemberships: {
      readonly totalCount: number;
    };
  } | null;
  readonly " $fragmentType": "QuizSubmissionsOverviewContentHeader_ContentUsageFragment";
};
export type QuizSubmissionsOverviewContentHeader_ContentUsageFragment$key = {
  readonly " $data"?: QuizSubmissionsOverviewContentHeader_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionsOverviewContentHeader_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizSubmissionsOverviewContentHeader_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "roles",
              "value": [
                "member"
              ]
            }
          ],
          "concreteType": "ProductMembershipNodeConnection",
          "kind": "LinkedField",
          "name": "productMemberships",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "productMemberships(roles:[\"member\"])"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "66db1f9c0b2622d1ac0cd39d2712a73c";

export default node;
